import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from 'components/translation/Translation';
import { Camera16 } from '@carbon/icons-react';
import GalleryButtons from 'components/pages/school-profile-page/components/GalleryButtons/GalleryButtons';
import { useResponsiveRender } from 'hooks/useResponsiveRender';
import GalleryGridMobile from './gallery-grid-mobile';
import styles from './gallery-grid.module.scss';
import pixels from 'util/pixels';

export const GalleryGrid = ({ school, openViewPhotos }) => {
  const { id, name, images } = school;

  const { renderMobile } = useResponsiveRender({ breakpoint: 767 });

  const sortedImages = useMemo(() => {
    let _images = [...images];
    return _images?.sort((a, b) => {
      if (a.profile_image && !b.profile_image) {
        return -1; // 'a' comes first if it's profile_image and 'b' is not
      }
      if (!a.profile_image && b.profile_image) {
        return 1; // 'b' comes first if it's profile_image and 'a' is not
      }
      return 0; // maintain the original order if both are profile_image or both are not
    });
  }, [images]);

  if (renderMobile) {
    return <GalleryGridMobile imagesMobile={sortedImages} name={name} />;
  }

  return (
    <section className={styles.galleryContainer}>
      <div className={styles.mobilebuttons_top}>
        <GalleryButtons schoolId={id} schoolName={name} />
      </div>

      <GridPhotos photos={sortedImages.slice(0, 5)} name={name} />

      {images.length > 1 && <ViewMorePhotos openViewPhotos={openViewPhotos} />}
    </section>
  );
};

const GridPhotos = ({ photos, name }) => {
  if (photos.length < 3) {
    return <Photo src={photos[0]} name={name} profileImage />;
  }

  return (
    <div className={styles.content}>
      <div className={styles.main}>
        <Photo src={photos[0]} name={name} profileImage />
      </div>
      <div className={styles.strip}>
        {photos.slice(1).map((photo, idx) => (
          <Photo key={idx} src={photo} name={`${name}-${idx + 1}`} />
        ))}
      </div>
    </div>
  );
};

const Photo = ({ src, name, profileImage = false }) => {
  const filenameArr = src?.filename.split('/');
  const largeName = `/${profileImage ? 'l' : 's'}-${filenameArr.pop()}`;

  const largeUrl = filenameArr.join('/') + largeName;

  return (
    <figure className={styles.image}>
      <img
        src={largeUrl}
        onError={(error) => {
          if (error.target.src !== src?.filename) {
            error.target.src = src?.filename;
          } else {
            error.target.src = pixels.lightblue;
          }
        }}
        alt={`${name} - ${+1}`}
      />
    </figure>
  );
};

const ViewMorePhotos = ({ openViewPhotos }) => {
  return (
    <div className={styles.mobilebuttons_bottom}>
      <button onClick={openViewPhotos}>
        <Camera16 />
        <p>{t('schoolProfile:gallery.viewAll')}</p>
      </button>
    </div>
  );
};

GalleryGrid.propTypes = {
  school: PropTypes.object.isRequired,
  openViewPhotos: PropTypes.func.isRequired,
};
