import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Buttonv2 as Button } from 'schola-components';
import { Checkmark16, Idea16 } from '@carbon/icons-react';
import { t, tString } from 'components/translation/Translation';

// styles
import styles from './profile-help-banner.module.scss';
import { useDispatch } from 'react-redux';
import { toggleSpotHelp } from 'redux/slices/school-profile.slice';
import { Link } from 'react-router';

const ListItem = ({ children }) => {
  return (
    <li className={styles.item}>
      <Checkmark16 /> <span>{children}</span>
    </li>
  );
};

export const ProfileHelpBanner = ({ className, buttonLabel, claimed, showImage = true }) => {
  const dispatch = useDispatch();
  const contactAction = () => dispatch(toggleSpotHelp());

  const classProfileHelpBanner = {
    [styles.helpBanner]: true,
    [`${className}`]: className,
  };

  const title = claimed
    ? t('schoolProfile:helpUnclaimed.titleClaimed')
    : t('schoolProfile:helpUnclaimed.titleUnclaimed');

  return (
    <div className={classNames(classProfileHelpBanner)}>
      <div className={styles.claimedContainer}>
        {showImage && (
          <div className={styles.image}>
            <img
              src="https://static.schola.com/profile/schola-concepts-nurture-2.webp"
              alt="Schola® Help"
              width="160"
              height="160"
              loading="lazy"
            />
            <img
              src="https://static.schola.com/profile/profile-help-banner-star.svg"
              alt="Schola®"
              className={styles.rightStar}
              width="16"
              height="17"
              loading="lazy"
            />
            <img
              src="https://static.schola.com/profile/profile-help-banner-star.svg"
              alt="Schola®"
              className={styles.leftStar}
              width="16"
              height="17"
              loading="lazy"
            />
          </div>
        )}

        <h3 className={`${styles.title} heading-6`}>{title}</h3>
        <ul>
          <ListItem>{tString('schoolProfile:helpUnclaimed.list.item1')}</ListItem>
          <ListItem>{tString('schoolProfile:helpUnclaimed.list.item2')}</ListItem>
          <ListItem>{tString('schoolProfile:helpUnclaimed.list.item3')}</ListItem>
          <ListItem>{tString('schoolProfile:helpUnclaimed.list.item4')}</ListItem>
        </ul>
        <Button className={styles.button_primary} color="secondary" fullWidth fullWidthMobile onClick={contactAction}>
          {buttonLabel}
        </Button>

        {/* <div className={styles.learn}>
          <Link href="/about-us">
            <Idea16 />
            {tString('schoolProfile:helpUnclaimed.learnMore')}
          </Link>
        </div> */}
      </div>
    </div>
  );
};

ProfileHelpBanner.propTypes = {
  className: propTypes.string,
  buttonLabel: propTypes.oneOfType([propTypes.object, propTypes.string]),
  contactAction: propTypes.func,
};

ProfileHelpBanner.defaultProps = {
  className: null,
  buttonLabel: t('schoolProfile:helpUnclaimed.button'),
};
