import React, { useEffect, useState } from 'react';

export const MobileLayout = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const getLayout = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;

      setIsVisible(windowHeight > 500);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', getLayout);
    return () => window.removeEventListener('scroll', getLayout);
  }, []);

  if (!isVisible) return null;

  return <div>{children}</div>;
};
