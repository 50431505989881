import React, { useEffect, useState } from 'react';
import { ContactStage, FeedbackResponse, MessageChip, Disclaimer } from './components';
import { ScholaPartnerBanner } from 'components/schola-partner-banner/schola-partner-banner';
import { useDispatch, useSelector } from 'react-redux';
import {
  setStage,
  getMemoValidStudents,
  validateContact,
  setStatusLead,
  setContactType,
  setSchoolId,
  setSubmitting,
  setZip,
  closeContact,
  activateErrors,
} from 'redux/slices/contact-school.slice';
import { CONTACT_STAGES, CONTACT_TYPE, STATUS_LEAD } from 'redux/slices/types';
import { Buttonv2 as Button } from 'schola-components';
import { useContactSchoolMutation, useGetSchoolByIdQuery } from 'redux/api/school-api';
import { getContactType, isZipcodeIncluded, leadAdapter } from './utils';
import styles from './contact-school.module.scss';
import { t } from 'components/translation/Translation';
import { isEmpty } from 'lodash';

const { initial, students, contact, response } = CONTACT_STAGES;
const visitor = require('visitor');

export const ContactSchool = ({
  school,
  isMultiple = false,
  schoolIds = [],
  includeNavigation = false,
  isModal = false,
  source = '',
  searchHistoryId = null,
  externalCampaignId = null,
  metaFbclid = null,
  googleGclid = null,
}) => {
  const dispatch = useDispatch();
  const { stage } = useSelector((state) => state.contactSchool);
  const schoolId = school ? school.id : 0; // needed to set schoolId
  // const { schoolId } = useSelector((state) => state.profile);
  // const { data: school } = useGetSchoolByIdQuery(schoolId, {
  //   skip: isMultiple,
  // });

  useEffect(() => {
    // define the type of contact depending on school type and active plan
    if (isMultiple) {
      const contactType = getContactType(null, isMultiple);
      dispatch(setContactType(contactType));

      // from multiple schools contact
      dispatch(setSchoolId(schoolIds));
    } else {
      const contactType = getContactType(school, isMultiple);
      dispatch(setContactType(contactType));

      if (isZipcodeIncluded(contactType)) {
        dispatch(setZip());
      }
      /* if (contactType === CONTACT_TYPE.unclaimed) {
        dispatch(setStage(contact));
      } */

      // from profile
      dispatch(setSchoolId(schoolId)); // sets school id in case is not from multiple schools
    }
  }, []);

  return (
    <ContactLayout
      includeNavigation={includeNavigation}
      isModal={isModal}
      source={source}
      searchHistoryId={searchHistoryId}
      externalCampaignId={externalCampaignId}
      metaFbclid={metaFbclid}
      googleGclid={googleGclid}>
      <DynamicStage stage={stage} />
    </ContactLayout>
  );
};

const DynamicStage = ({ stage }) => {
  const { errorsActive } = useSelector((state) => state.contactSchool);
  const errorsContact = useSelector(validateContact);

  const stages = {
    [contact]: <ContactStage errors={errorsActive && errorsContact} />,
    [response]: <FeedbackResponse />,
  };

  return stages[stage];
};

const ContactLayout = ({
  children,
  includeNavigation,
  isModal,
  source,
  searchHistoryId,
  externalCampaignId,
  metaFbclid,
  googleGclid,
}) => {
  const { stage, contactType, statusLead } = useSelector((state) => state.contactSchool);

  const title = getContactTitle(stage, contactType === CONTACT_TYPE.unclaimed);

  return (
    <div className={styles.container}>
      {includeNavigation && <BarProgress className="spacing-mb-16" />}

      <div className={styles.container_content}>
        {includeNavigation && (
          <>
            <div className={styles.nortonContainer}>
              <h4 style={{ fontSize: '1.5rem' }}>{title}</h4>
              <div className={styles.norton}>
                <img
                  src="https://static.schola.com/profile/norton.webp"
                  alt="Norton Symantec"
                  width="176"
                  height="75"
                  loading="lazy"
                />
              </div>
            </div>
          </>
        )}
        {statusLead === STATUS_LEAD.error && <MessageChip className="spacing-mt-24" />}
        {contactType === CONTACT_TYPE.recruiterPro && stage === CONTACT_STAGES.initial && (
          <ScholaPartnerBanner className="spacing-mt-16" />
        )}
        <div className="spacing-mt-16 spacing-pr-16">{children}</div>
        {stage !== CONTACT_STAGES.response && (
          <Disclaimer className="spacing-mt-24 spacing-pr-16" contact={stage === 'contact'} />
        )}
        {includeNavigation && (
          <ActionButtons
            className={styles.buttonsContainer}
            isModal={isModal}
            source={source}
            searchHistoryId={searchHistoryId}
            externalCampaignId={externalCampaignId}
            metaFbclid={metaFbclid}
            googleGclid={googleGclid}
          />
        )}
      </div>
    </div>
  );
};

export const BarProgress = ({ className }) => {
  const { stage, contactType } = useSelector((state) => state.contactSchool);

  const isClaimed = contactType !== CONTACT_TYPE.unclaimed;

  const totalStages = 2;
  const indexStages = {
    /* [initial]: 0, */
    [contact]: 1,
    [response]: 2,
  };
  const progress = (indexStages[stage] * 100) / totalStages;

  return <div className={`${styles.barProgress} ${className}`} style={{ width: `${progress}%` }} />;
};

export const ActionButtons = ({
  className,
  isModal,
  source,
  searchHistoryId = null,
  externalCampaignId = null,
  metaFbclid = null,
  googleGclid = null,
}) => {
  const [createContact, { isLoading, isUninitialized }] = useContactSchoolMutation({
    fixedCacheKey: 'lead-post',
  });

  const dispatch = useDispatch();

  const areStudentsValid = useSelector(getMemoValidStudents);

  const isContactValid = useSelector(validateContact);

  const {
    stage,
    schoolId,
    contact: contactInfo,
    language,
    students: studentsInfo,
    isContactSubmitting,
    contactType,
  } = useSelector((state) => state.contactSchool);

  const onCreateContact = async () => {
    if (window.gtag) {
      window.gtag('event', 'School Page Contact Form Complete', { page_url: window.location.href });
    }

    if (contactType === CONTACT_TYPE.multiple) {
      const promises = schoolId.map(async (id) => {
        const leadPayload = leadAdapter({ schoolId: id, contact: contactInfo, students: studentsInfo, language });
        // consider source
        if (source) {
          leadPayload.source = source;
          delete leadPayload.lead_source_id;
        }
        if (searchHistoryId) {
          leadPayload.search_history_id = searchHistoryId;
        }
        if (externalCampaignId) {
          leadPayload.external_campaign_id = externalCampaignId;
        }
        if (metaFbclid) {
          leadPayload.meta_fbclid = metaFbclid;
        }
        if (googleGclid) {
          leadPayload.google_gclid = googleGclid;
        }
        visitor.createEvent('School Profile', 'Contact Form Complete', window.location.href, leadPayload, 'click');
        return createContact(leadPayload);
      });

      try {
        await Promise.all(promises);
        dispatch(setStatusLead(STATUS_LEAD.success));
        dispatch(setStage(response));
      } catch (error) {
        console.log(error);
      }
    } else {
      // do normal request
      const leadPayload = leadAdapter({ schoolId, contact: contactInfo, students: studentsInfo, language });
      // consider source
      if (source) {
        leadPayload.source = source;
        delete leadPayload.lead_source_id;
      }
      if (searchHistoryId) {
        leadPayload.search_history_id = searchHistoryId;
      }
      if (externalCampaignId) {
        leadPayload.external_campaign_id = externalCampaignId;
      }
      if (metaFbclid) {
        leadPayload.meta_fbclid = metaFbclid;
      }
      if (googleGclid) {
        leadPayload.google_gclid = googleGclid;
      }
      try {
        createContact(leadPayload);
        dispatch(setStage(response));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isClaimed = contactType !== CONTACT_TYPE.unclaimed;

  const contactCallback = () => {
    if (isEmpty(isContactValid)) {
      dispatch(setSubmitting());
    } else {
      dispatch(setSubmitting(false));
    }

    if (isContactSubmitting && Object.keys(isContactValid).length === 0) {
      onCreateContact();
    }
  };

  useEffect(() => {
    if (isContactSubmitting && Object.keys(isContactValid).length === 0 && isUninitialized) {
      onCreateContact();
    }
  }, [isContactSubmitting]);

  const buttonsTest = {
    [students]: {
      prev: () => dispatch(setStage(contact)),
      next: {
        id: `btnContact${isModal === true ? 'Modal' : ''}Send`,
        label: t('contactSchool:sendButton'),
        className: '',
        disabled: !areStudentsValid || isLoading,
        onClick: () => contactCallback(),
      },
    },
    [contact]: {
      prev: () => closeContact(),
      next: {
        id: `btnContact${isModal === true ? 'Modal' : ''}Send`,
        label: t('contactSchool:submitApplication'),
        className: 'school-lead-submit',
        // disabled: !Object.values(contactInfo).every((item) => item) || isLoading,
        onClick: () => {
          dispatch(activateErrors());
          contactCallback();
        },
      },
    },
  };

  if (stage === CONTACT_STAGES.response) return null;

  return (
    <div className={`${styles.buttonsGroup} ${className}`}>
      {stage !== CONTACT_STAGES.contact && (
        <Button color="secondary" onClick={buttonsTest[stage]?.prev} fullWidth>
          {t('contactSchool:prev')}
        </Button>
      )}
      <Button
        id={buttonsTest[stage].next?.id}
        className={buttonsTest[stage].next?.className}
        color="primary"
        onClick={buttonsTest[stage].next.onClick}
        disabled={buttonsTest[stage].next.disabled}
        fullWidth>
        {isLoading ? t('contactSchool:submitApplication') : buttonsTest[stage].next.label}
      </Button>
    </div>
  );
};

export const getContactTitle = (stage, isUnclaimed) => {
  const titles = {
    [initial]: t('contactSchool:message.title'),
    [students]: t('contactSchool:students.title'),
    [contact]: t('contactSchool:contact.title'),
  };

  return titles[stage];
};

ContactSchool.ActionButtons = ActionButtons;
ContactSchool.BarProgress = BarProgress;
