import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  schoolId: null,
  screenWidth: null,
  scheduleTourModal: false,
  availableTours: false,
  isSpotContactOpen: false,
  isShareSchoolOpen: false,
  isGalleryOpen: false,
  isTourOpen: false,
  isUnclaimedOpen: false,
  isExternalAppJotform: false,
};

export const schoolProfile = createSlice({
  name: 'schoolProfile',
  initialState,
  reducers: {
    updateSchoolId: (state, { payload }) => {
      state.schoolId = payload;
    },
    updateScreenWidth: (state, { payload }) => {
      state.screenWidth = payload;
    },
    toggleTourModal: (state) => {
      state.isTourOpen = !state.isTourOpen;
    },
    setTourAvailable: (state, { payload }) => {
      state.availableTours = payload;
    },
    toggleSpotHelp: (state) => {
      state.isSpotContactOpen = !state.isSpotContactOpen;
    },
    toggleShare: (state) => {
      state.isShareSchoolOpen = !state.isShareSchoolOpen;
    },
    toggleGallery: (state) => {
      state.isGalleryOpen = !state.isGalleryOpen;
    },
    toggleUnclaimed: (state) => {
      state.isUnclaimedOpen = !state.isUnclaimedOpen;
    },
    setExternalJotform: (state, { payload }) => {
      state.isExternalAppJotform = payload;
    },
  },
});

export default schoolProfile.reducer;

export const {
  updateSchoolId,
  toggleTourModal,
  setTourAvailable,
  toggleSpotHelp,
  updateScreenWidth,
  toggleShare,
  toggleGallery,
  toggleUnclaimed,
  setExternalJotform,
} = schoolProfile.actions;
