import React, { useMemo } from 'react';
import StarsExperience from './stars-experience';
import styles from './thumbnail.module.scss';
import { logosReviews } from '../../utils';

/**
 *
 * @param {object} props
 * @param {object[]} props.externalReviews
 *
 */
export const Thumbnail = ({ externalReviews }) => {
  if (externalReviews.length === 0) {
    return null;
  }

  const { hasMultipleSiteReviews } = useMemo(() => {
    const hasMultipleSiteReviews = externalReviews.filter((site) => site.ranking > 0).length > 1;

    return { hasMultipleSiteReviews };
  }, [externalReviews]);

  return (
    <section className={styles.externalReviews}>
      {externalReviews.map((item, index) => (
        <div key={item.id} className={styles.externalReviewsSite}>
          <img className={styles.image} src={logosReviews[item.site]} alt={`${item.site} Logo`} />
          <StarsExperience
            color="rgb(239, 210, 76)"
            value={item.ranking}
            spaceBetween={5}
            size={14}
            notPointer
            onlyView
          />
          <span className="body-small">{item.ranking}</span>
          {index < externalReviews.length - 1 && hasMultipleSiteReviews ? (
            <span className={styles.dot}>&#183;</span>
          ) : null}
        </div>
      ))}
    </section>
  );
};
