import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, tString } from 'components/translation/Translation';
import classNames from 'classnames';
import styles from './more-about.module.scss';
import { ExtendedFeature } from '../../extended-feature/extended-feature';
import { getFeatureIcon } from 'components/pages/school-profile-page/utils/getFeatureIcon';
import VideoSlider from './video-slider';
import { SchoolTypeBanner, SocialLinks } from '..';
import { validTypes } from '../../utils';
import HtmlParser from 'html-react-parser';
import getVideoId from 'util/video';

const EXTENDED_FEATURES = {
  ACCREDITATIONS: 'School Accreditations',
  ADDITIONAL_LANGUAGE: 'Additional language classes',
  SCHEDULE: 'Schedule',
  SCHOLARSHIP_PROGRAMS: 'Scholarship Programs',
  EXTRA_FEATURES: 'Extra Features',
  SCHOOL_SUPPORTS: 'School Supports',
};

export const MoreAbout = ({ school, screenWidth }) => {
  const { videos, description, features, mission, vision } = school;
  const [videoSrc, setVideoSrc] = useState('');
  const [accreditationList, setAccreditationList] = useState(null);
  const [additionalLanguageList, setAdditionalLanguageList] = useState(null);
  const [scheduleList, setScheduleList] = useState(null);
  const [scholarShipProgramList, setScholarShipProgramList] = useState(null);
  const [extraFeaturesList, setExtraFeaturesList] = useState(null);
  const [schoolSupportList, setSchoolSupportList] = useState(null);
  const [videoID, setvideoID] = useState('');

  const displayVideo = videos ? videos.filter((video) => video != null)[0] : undefined;
  const _videos = videos ? videos.filter((video) => video != null) : undefined;

  const getDisplayVideo = () => {
    if (displayVideo) {
      const metadata = getVideoId(displayVideo.embed_code);
      if (metadata) {
        let _video;
        const _videoID = metadata.id;

        if (/youtube/.test(displayVideo.embed_code)) {
          _video = `${displayVideo.embed_code}?rel=0`;
        } else {
          _video = displayVideo.embed_code;
        }
        setVideoSrc(_video);
        setvideoID(_videoID);
      }
    }
  };

  const getExtendedFeatures = () => {
    if (!features || features.length === 0) return;
    const auxAccreditation = [];
    const auxAdditionalLanguage = [];
    const auxSchedule = [];
    const auxScholarShipProgram = [];
    const auxExtraFeatures = [];
    const auxSchoolSupport = [];

    features.forEach((feature) => {
      const groupName = feature?.group?.name || '';

      switch (groupName.toLowerCase()) {
        case EXTENDED_FEATURES.ACCREDITATIONS.toLowerCase():
          auxAccreditation.push(feature.name);
          break;
        case EXTENDED_FEATURES.ADDITIONAL_LANGUAGE.toLowerCase():
          auxAdditionalLanguage.push(feature.name);
          break;
        case EXTENDED_FEATURES.EXTRA_FEATURES.toLowerCase():
          auxExtraFeatures.push(feature.name);
          break;
        case EXTENDED_FEATURES.SCHEDULE.toLowerCase():
          auxSchedule.push(feature.name);
          break;
        case EXTENDED_FEATURES.SCHOLARSHIP_PROGRAMS.toLowerCase():
          auxScholarShipProgram.push(feature.name);
          break;
        case EXTENDED_FEATURES.SCHOOL_SUPPORTS.toLowerCase():
          auxSchoolSupport.push(feature.name);
          break;
        default:
          break;
      }
    });
    setAccreditationList({
      icon: getFeatureIcon(EXTENDED_FEATURES.ACCREDITATIONS),
      features: auxAccreditation,
      title: EXTENDED_FEATURES.ACCREDITATIONS,
    });
    setAdditionalLanguageList({
      icon: getFeatureIcon(EXTENDED_FEATURES.ADDITIONAL_LANGUAGE),
      features: auxAdditionalLanguage,
      title: EXTENDED_FEATURES.ADDITIONAL_LANGUAGE,
    });
    setExtraFeaturesList({
      icon: getFeatureIcon(EXTENDED_FEATURES.EXTRA_FEATURES),
      features: auxExtraFeatures,
      title: EXTENDED_FEATURES.EXTRA_FEATURES,
    });
    setScheduleList({
      icon: getFeatureIcon(EXTENDED_FEATURES.SCHEDULE),
      features: auxSchedule,
      title: EXTENDED_FEATURES.SCHEDULE,
    });
    setScholarShipProgramList({
      icon: getFeatureIcon(EXTENDED_FEATURES.SCHOLARSHIP_PROGRAMS),
      features: auxScholarShipProgram,
      title: EXTENDED_FEATURES.SCHOLARSHIP_PROGRAMS,
    });
    setSchoolSupportList({
      icon: getFeatureIcon(EXTENDED_FEATURES.SCHOOL_SUPPORTS),
      features: auxSchoolSupport,
      title: EXTENDED_FEATURES.SCHOOL_SUPPORTS,
    });
  };
  useEffect(() => {
    getDisplayVideo();
    getExtendedFeatures();
  }, []);

  const className = 'more-about';
  const AboutClasses = {
    [className]: className,
    [styles['more-about']]: true,
  };

  return (
    <div className={classNames(AboutClasses)} id="about">
      <h3 className={styles.title}>{t('schoolProfile:moreAboutTitle')}</h3>

      {mission && mission !== '<p></p>' && mission !== '<p><br></p>' && (
        <div className={`${styles.resetStyles} ${styles.description}`}>
          <h3>{t('schoolProfile:ourMission')}:</h3>
          {HtmlParser(mission.replace(/\r?\n/g, '<br />'))}
        </div>
      )}

      {vision && vision !== '<p></p>' && vision !== '<p><br></p>' && (
        <div className={`${styles.resetStyles} ${styles.description}`}>
          <h3>{t('schoolProfile:ourVision')}:</h3>
          {HtmlParser(vision.replace(/\r?\n/g, '<br />'))}
        </div>
      )}

      {videos.length === 1 && (
        <div className={styles.video}>
          <iframe
            width="100%"
            height="350"
            src={videoSrc}
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${videoID}?autoplay=1><img src=https://img.youtube.com/vi/${videoID}/sddefault.jpg alt='School Video'><span>▶</span></a>`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={videoSrc}
          />
        </div>
      )}
      {videos.length > 1 && (
        <div className={styles.video}>
          <VideoSlider videos={_videos} />
        </div>
      )}

      {description.length > 0 && (
        <div className={`${styles.resetStyles} ${styles.description}`}>
          {HtmlParser(description.replace(/\r?\n/g, '<br />'))}
        </div>
      )}
      {screenWidth < 768 && validTypes.includes(school.type) && (
        <div className={styles.bannerContainer} data-testid="about-banner">
          <SchoolTypeBanner type={school.type} shape="square" />
        </div>
      )}
      <div className={styles.extendedFeaturesContainer}>
        {accreditationList && accreditationList.features.length > 0 && (
          <ExtendedFeature
            icon={accreditationList.icon}
            title={tString('schoolProfile:extendedFeatures.accreditations')}
            features={accreditationList.features}
          />
        )}
        {additionalLanguageList && additionalLanguageList.features.length > 0 && (
          <ExtendedFeature
            icon={additionalLanguageList.icon}
            title={tString('schoolProfile:extendedFeatures.languagePrograms')}
            features={additionalLanguageList.features}
          />
        )}
        {schoolSupportList && schoolSupportList.features.length > 0 && (
          <ExtendedFeature
            icon={schoolSupportList.icon}
            title={tString('schoolProfile:extendedFeatures.scholarshipPrograms')}
            features={schoolSupportList.features}
          />
        )}
        {scheduleList && scheduleList.features.length > 0 && (
          <ExtendedFeature
            icon={scheduleList.icon}
            title={tString('schoolProfile:extendedFeatures.schedule')}
            features={scheduleList.features}
          />
        )}
        {extraFeaturesList && extraFeaturesList.features.length > 0 && (
          <ExtendedFeature
            icon={extraFeaturesList.icon}
            title={tString('schoolProfile:extendedFeatures.supportStaff')}
            features={extraFeaturesList.features}
          />
        )}
        {scholarShipProgramList && scholarShipProgramList.features.length > 0 && (
          <ExtendedFeature
            icon={scholarShipProgramList.icon}
            title={tString('schoolProfile:extendedFeatures.extraFeatures')}
            features={scholarShipProgramList.features}
          />
        )}
      </div>

      {screenWidth < 768 && (
        <div className={styles.socialLinksContainer}>
          <h3 className={styles.socialLinkTittle}>{tString('schoolProfile:socialLinksTitle')}</h3>
          <SocialLinks school={school} mobile />
        </div>
      )}
    </div>
  );
};

MoreAbout.propTypes = {
  school: PropTypes.object.isRequired,
  screenWidth: PropTypes.number.isRequired,
};
