import { LinkButton } from 'schola-components';
import { t } from 'components/translation/Translation';
import config from 'config';
import styles from './message-unclaimed.module.scss';

const MessageUnclaimed = ({ schoolId }) => {
  return (
    <div className={styles['section-banner']} data-testid="schoolClaimMain">
      <div className={styles['section-banner-content']}>
        <div className={styles['section-banner-text']}>
          <h3 className="heading-4 spacing-mb-8" data-testid="schoolClaimHeader">
            {t('schoolProfile:claim.title')}
          </h3>
          <div className="body-regular spacing-mb-16" data-testid="schoolClaimSubheader">
            {t('schoolProfile:claim.subtitle')}
          </div>
          <div className={styles['section-banner-button']} data-testid="schoolClaimButton">
            <LinkButton
              color="secondary"
              theme="outlined"
              target="_blank"
              fullWidthMobile
              href={`${config.adminHost}/claim/${schoolId}`}>
              {t('schoolProfile:claim.button')}
            </LinkButton>
          </div>
        </div>
        <div className={styles['section-banner-img']} data-testid="claimImg">
          <img src="https://static.schola.com/profile/image-unclaimed.webp" alt="Schola®" width="90" height="90" />
        </div>
        <input type="hidden" className="btnClaimSchool" value="true" />
      </div>
    </div>
  );
};

export default MessageUnclaimed;
