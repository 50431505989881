import React, { useEffect, useState } from 'react';
import { ButtonRetool, MatchLabel, ProfileImage, SelectSchool, TabsSelector } from '..';
import { useGetSchoolByIdQuery } from 'redux/api/school-api';
import { GalleryModal, SchoolMap, SchoolType } from 'components/pages/school-profile/components';
import { SneakPeek } from 'components/pages/school-profile/sneak-peek/sneak-peek';
import { DisplayCategories } from 'components/pages/school-profile/info-section/info-section';
import { SchoolFeatures } from 'components/pages/school-profile/school-features/school-features';
import { useFeaturesJoin } from 'components/pages/school-profile/hooks/useFeaturesJoin';
import { tString } from 'components/translation/Translation';
import { useDispatch, useSelector } from 'react-redux';
import { toggleGallery } from 'redux/slices/school-profile.slice';

import styles from './school-details.module.scss';
import MissionCTA from 'components/pages/school-profile/mission-cta/mission-cta';
import { SeeMoreLess } from 'components/pages/school-profile/components/see-more/see-more';
import HtmlParser from 'html-react-parser';
import { browserHistory, Link } from 'react-router';
import { InfoCircleOutlined } from '@ant-design/icons';

export const SchoolDetails = ({ schools = [], handleContact, location }) => {
  const [currentSchool, setCurrentSchool] = useState({});
  const [availableSpots, setAvailableSpots] = useState([]);

  useEffect(() => {
    const spotsSessionStorage = sessionStorage.getItem('spotsAvailable');
    if (spotsSessionStorage) {
      const spots = JSON.parse(spotsSessionStorage);
      setAvailableSpots(spots);
    } else {
      const SPOTS = [];
      const getRandomSpot = () => Math.ceil(Math.random() * 8);
      while (SPOTS.length < 4) {
        let spot = getRandomSpot();
        if (!SPOTS.includes(spot)) {
          SPOTS.push(spot);
        }
      }

      const withSpots = schools.map((school, index) => {
        return {
          id: school.id,
          spot: SPOTS[index],
        };
      });

      setAvailableSpots(withSpots);
      sessionStorage.setItem('spotsAvailable', JSON.stringify(withSpots));
    }
  }, [schools]);

  useEffect(() => {
    setCurrentSchool(schools[0]);
  }, [schools]);

  const handleSchool = (id) => {
    setCurrentSchool(schools.find((item) => item.id === id));
  };

  return (
    <section className={styles.detailsSection}>
      <h3 style={{ textAlign: 'center', marginBottom: '1rem' }}>See School Details Of</h3>
      <SelectSchool schools={schools} handleSchool={handleSchool} currentSchool={currentSchool} />
      <div className={styles.container}>
        <TabsSelector schools={schools} handleSchool={handleSchool} currentSchool={currentSchool} />
        <div className={styles.content}>
          <div className={styles.matchLabelContainer}>
            <MatchLabel matchLevel={currentSchool?.matchLevel} className={styles.matchLabel} />
          </div>
          <h2 className={styles.schoolName}>{currentSchool?.name}</h2>
          <SchoolInfo
            id={currentSchool?.id}
            name={currentSchool?.name}
            image={currentSchool?.profile_image}
            matchLevel={currentSchool?.matchLevel}
            handleContact={() => handleContact(currentSchool)}
            spot={availableSpots.find((school) => school.id === currentSchool?.id)?.spot}
          />
        </div>
      </div>
    </section>
  );
};

const renderHtml = (content) => {
  const fullContentString = content.replace(/\r?\n/g, '<br />');
  const fullContent = HtmlParser(fullContentString);
  return fullContent;
};

const SchoolInfo = ({ id, image, name, handleContact, spot }) => {
  const { pathname } = browserHistory.getCurrentLocation();
  const { isLoading, isSuccess, data: school } = useGetSchoolByIdQuery(id, { skip: !id });
  const featureCategories = useFeaturesJoin(school?.features);
  const dispatch = useDispatch();
  const { isGalleryOpen } = useSelector((state) => state.profile);

  const handleGallery = () => {
    dispatch(toggleGallery());
  };
  return (
    <div className={styles.infoContainer}>
      <div className={styles.display}>
        <div className={styles.header}>
          <figure className={styles.image}>
            <ProfileImage photo={image} name={name} />
          </figure>
          {/*  <GalleryGrid school={school} openViewPhotos={handleGallery} /> */}
        </div>
        <div>
          {isSuccess && (
            <div className={styles.content}>
              {school.description && (
                <div className="spacing-my-16">
                  <SeeMoreLess content={school.description} limit={200} />
                </div>
              )}

              <SchoolType
                type={school.type}
                grades={school.grades}
                features={school.features}
                claimed={school.claimed}
              />

              {school.mission && (
                <div>
                  <p className={styles.missionLabel}>Mission</p>
                  <p>{renderHtml(school.mission)}</p>
                </div>
              )}

              {school.vision && (
                <div className="spacing-mt-16">
                  <p className={styles.missionLabel}>Vision</p>
                  <p>{renderHtml(school.vision)}</p>
                </div>
              )}

              <div className={styles.buttonContainer}>
                <ButtonRetool color="pro" className="spacing-mt-32" onClick={handleContact}>
                  {pathname === '/schola-match-b' ? 'Request Info ' : 'Start Application'}
                </ButtonRetool>
                <span className={styles.tooltip}>
                  {/* <b>{spot}</b> Spots left */}
                  <InfoCircleOutlined /> <b>Hurry!</b> Seats Filling Up Fast
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        {isLoading && <span>Loading...</span>}

        {isSuccess && (
          <>
            <SneakPeek school={school} />

            <div className={styles.contentFeatures}>
              <SchoolMap school={school} className="spacing-mb-16" />

              {featureCategories.topFeatures && (
                <SchoolFeatures
                  topFeatures={featureCategories.topFeatures}
                  title="Campus Highlights"
                  description={tString('schoolProfile:schoolFeatures.description')}
                  className="spacing-mt-16"
                />
              )}
              <DisplayCategories school={school} />
            </div>
            <MissionCTA className={styles.missionCTA} claimed={school.claimed} />
            <p className={styles.caption}>
              Schools without a check mark may have longer response times, but we're committed to contacting them for
              you. These selections are your best matches based on your ScholaMatch™ preferences. We prioritize your
              child's education, ensuring only top matches are shown.
            </p>
            <p className={styles.linkToMap}>
              <Link target="_blank" href={`/schools-results/${location.search}`}>
                Click to explore more schools matching your criteria.
              </Link>
            </p>
          </>
        )}
      </div>
      {isGalleryOpen && (
        <GalleryModal
          schoolName={school.name}
          images={school?.images}
          isVisible={isGalleryOpen}
          openViewPhotos={handleGallery}
        />
      )}
    </div>
  );
};
