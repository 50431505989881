import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { tString } from 'components/translation/Translation';
import { openContact } from 'redux/slices/contact-school.slice';
import { useDispatch } from 'react-redux';
import styles from './mission-cta.module.scss';
import { ButtonRetool } from 'components/pages/schola-match-results/components';
import { browserHistory } from 'react-router';

const visitor = require('../../../../visitor');

const MissionCTA = ({ className, claimed, schoolId }) => {
  const dispatch = useDispatch();
  const contactAction = (e) => {
    if (window.gtag) {
      window.gtag('event', 'School Page Contact Form Start', { page_url: window.location.href });
    }
    const id = e.target.id || e.target.parentElement.id;
    visitor.createEvent(
      'School Profile',
      'Contact Form Start',
      window.location.href,
      { trigger: id, school_id: schoolId },
      'click'
    );
    dispatch(openContact());
  };
  /* const className = 'mission-section'; */
  const MissionClasses = {
    [className]: className,
    [styles['mission-section']]: true,
  };

  const testimonialCSS = {
    display: 'flex',
    gap: '1rem',
    minHeight: '95px',
    alignItems: 'center',
  };
  const { pathname } = browserHistory.getCurrentLocation();
  return (
    <section className={classNames(MissionClasses)}>
      <h3 className={styles.title}>
        {claimed ? tString('schoolProfile:mission.title') : tString('schoolProfile:mission.unclaimedTitle')}
      </h3>
      <p className="spacing-my-32 text-center" style={{ fontSize: '1rem', lineHeight: '1.5rem' }}>
        {tString('schoolProfile:mission.subtitle')}
      </p>
      <div className={styles.grid}>
        <div className={styles.item}>
          <div className="testimonial-content" style={testimonialCSS}>
            <div className="img-testimonial">
              <img
                style={{ minWidth: '33px', height: '33px' }}
                src="https://static.schola.com/profile/img-testimonial-01.webp"
                alt="schola testimonial 01"
                width="33"
                height="33"
              />
            </div>
            <div className="testimonial" style={{ height: 'fit-content' }}>
              <div className="name-rate" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="font-bold">Nyshema</p>
                <img
                  style={{ width: '68px', height: '16px' }}
                  src="https://static.schola.com/profile/max-rate.webp"
                  alt="rate"
                  width="62"
                  height="16"
                />
              </div>
              <div className="text-testimonial">
                <p className="caption" style={{ fontSize: '11px', lineHeight: '20px', textAlign: 'left' }}>
                  “Since finding the right school with Schola, my son is more outgoing and social”
                </p>
              </div>
            </div>
          </div>
          {/* <h3>{tString('schoolProfile:mission.discover')}</h3>
          <p>
            {claimed
              ? tString('schoolProfile:mission.discoverText')
              : tString('schoolProfile:mission.unclaimedDiscoverText')}
          </p> */}
        </div>
        {/* <img className={styles.divider} src="https://static.schola.com/profile/arrow.svg" /> */}
        <div className={styles.item}>
          <div className="testimonial-content" style={testimonialCSS}>
            <div className="img-testimonial">
              <img
                style={{ minWidth: '33px', height: '33px' }}
                src="https://static.schola.com/profile/img-testimonial-02-33.webp"
                width="33"
                height="16"
                alt="schola testimonial 02"
              />
            </div>
            <div className="testimonial" style={{ height: 'fit-content' }}>
              <div className="name-rate" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="font-bold">Maria</p>
                <img
                  style={{ width: '68px', height: '16px' }}
                  src="https://static.schola.com/profile/max-rate.webp"
                  alt="rate"
                  width="62"
                  height="16"
                />
              </div>
              <div className="text-testimonial">
                <p className="caption" style={{ fontSize: '11px', lineHeight: '20px', textAlign: 'left' }}>
                  “Schola helped me find the right school for my kids from start to finish.”
                </p>
              </div>
            </div>
          </div>
          {/*  <h3>{tString('schoolProfile:mission.connect')}</h3>
          <p>
            {claimed
              ? tString('schoolProfile:mission.connectText')
              : tString('schoolProfile:mission.unclaimedConnectText')}
          </p> */}
        </div>
        {/* <img className={styles.divider} src="https://static.schola.com/profile/arrow.svg" /> */}
        <div className={styles.item}>
          <div className="testimonial-content" style={testimonialCSS}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <img
                style={{ minWidth: '37px', height: '37px' }}
                src="https://static.schola.com/profile/google-rate-logo.webp"
                alt="google rate logo"
                width="38"
                height="38"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                className={styles.googleRate}
                src="https://static.schola.com/profile/reviews-google-rate.svg"
                alt="reviews google rate"
                width="160"
                height="20"
              />
            </div>
          </div>
          {/* <h3>{tString('schoolProfile:mission.enroll')}</h3>
          <p>
            {claimed
              ? tString('schoolProfile:mission.enrollText')
              : tString('schoolProfile:mission.unclaimedEnrollText')}
          </p> */}
        </div>
      </div>
      <div className={styles.button}>
        <ButtonRetool className={styles.buttonCTA} onClick={contactAction} id="btnContactModalOpenBanner">
          {pathname === '/schola-match' && <span>Start Application For All My Matches!</span>}
          {pathname === '/schola-match-b' && <span>Request Info</span>}
          {pathname !== '/schola-match' && pathname !== '/schola-match-b' && tString('contactSchool:contactSchool')}
        </ButtonRetool>
      </div>
    </section>
  );
};

export default MissionCTA;
