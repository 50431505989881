import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import auth from 'redux/auth/auth-reducers';
import feature from 'redux/feature/feature-reducers';
import landingPage from 'redux/landing-page/landing-page-reducers';
import lead from 'redux/lead/lead-reducers';
import school from 'redux/school/school-reducers';
import schoolProfile from 'redux/school-profile/school-profile-reducers';
import users from 'redux/user/user-reducers';
import location from 'redux/location/location-reducers';
import board, { boardFlowReducer } from 'redux/board/board-reducers';
import student from 'redux/student/student-reducers';
import teacher from 'redux/teacher/teacher-reducers';
import contactSchoolReducer from './slices/contact-school.slice';
import profile from './slices/school-profile.slice';
import schoolResults from './slices/school-results.slice';
import { scholaApi } from './api';

const reducers = combineReducers({
  auth,
  browser: createResponsiveStateReducer({
    tabletPortrait: 599,
    tabletLandscape: 899,
    desktop: 1199,
    extraSmall: 480,
    small: 575,
    medium: 767,
    large: 991,
    extraLarge: 1400,
  }),
  feature,
  landingPage,
  lead,
  school,
  schoolProfile,
  users,
  location,
  board,
  student,
  teacher,
  favorites: boardFlowReducer,
  contactSchool: contactSchoolReducer,
  profile,
  schoolResults,
  [scholaApi.reducerPath]: scholaApi.reducer,
});

export default reducers;
