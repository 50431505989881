import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  LogoFacebook24,
  LogoInstagram24,
  LogoTwitter24,
  LogoLinkedin24,
  LogoFacebook20,
  LogoInstagram20,
  LogoTwitter20,
  LogoLinkedin20,
  Wikis20,
  LogoYoutube20,
  LogoYoutube24,
} from '@carbon/icons-react';
import styles from './social-links.module.scss';
import { ArrowRight } from '../../assets/svg-assets';
import { TikTokOutlined } from '@ant-design/icons';
import classNames from 'classnames';

export const SocialLinks = ({ school, mobile }) => {
  const haveFacebook = school?.social_facebook ?? null;
  const haveInstagram = school?.social_instagram ?? null;
  const haveTwitter = school?.social_twitter ?? null;
  const haveLinkedin = school?.social_linkedin ?? null;
  const haveYoutube = school?.social_youtube ?? null;
  const haveTiktok = school?.social_tiktok ?? null;
  const haveWebsite = school?.website ?? null;

  const socialLinks = useMemo(
    () => [
      { link: haveFacebook, type: 'facebook', name: 'Facebook' },
      { link: haveInstagram, type: 'instagram', name: 'Instagram' },
      { link: haveTwitter, type: 'twitter', name: 'Twitter' },
      { link: haveLinkedin, type: 'linkedin', name: 'LinkedIn' },
      { link: haveYoutube, type: 'youtube', name: 'YouTube' },
      { link: haveTiktok, type: 'tiktok', name: 'TikTok' },
      { link: haveWebsite, type: 'web', name: 'Go to their Website' },
    ],
    [school]
  );

  const icons = useMemo(
    () => ({
      facebook: mobile ? <LogoFacebook20 /> : <LogoFacebook24 />,
      instagram: mobile ? <LogoInstagram20 /> : <LogoInstagram24 />,
      twitter: mobile ? <LogoTwitter20 /> : <LogoTwitter24 />,
      linkedin: mobile ? <LogoLinkedin20 /> : <LogoLinkedin24 />,
      youtube: mobile ? <LogoYoutube20 /> : <LogoYoutube24 />,
      tiktok: mobile ? (
        <TikTokOutlined style={{ fontSize: '20px' }} />
      ) : (
        <TikTokOutlined style={{ fontSize: '24px' }} />
      ),
      web: mobile ? <Wikis20 /> : null,
    }),
    []
  );

  const containerStyles = {
    [styles.listContainer]: mobile,
    [styles.links]: !mobile,
  };

  const listWrapperStyles = {
    [styles.listWrapper]: mobile,
  };

  const linkContainerStyles = {
    [styles.linkContainer]: mobile,
  };

  return (
    <>
      <div className={`${classNames(containerStyles)}`}>
        {socialLinks.map(
          (social) =>
            social?.link && (
              <div className={classNames(listWrapperStyles)} key={`Link to ${social.type}`}>
                <div className={classNames(linkContainerStyles)}>
                  <a
                    key={`Link to ${social.type}`}
                    href={social.link}
                    rel="noreferrer"
                    aria-label={social.name}
                    target="_blank">
                    {icons[social.type]}
                  </a>
                  {mobile && (
                    <a href={social.link} aria-label={`Link to Schola ${social.name}`} rel="noreferrer" target="_blank">
                      {social.name}
                    </a>
                  )}
                </div>
                {mobile && (
                  <div>
                    <a href={social.link} aria-label={`Link to Schola ${social.name}`} rel="noreferrer" target="_blank">
                      {ArrowRight}
                    </a>
                  </div>
                )}
              </div>
            )
        )}
      </div>
    </>
  );
};

SocialLinks.propTypes = {
  school: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
};
