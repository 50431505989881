import config from 'config';
import { browserHistory } from 'react-router';
import schoolUrl from 'util/schoolUrl';
import { applicationType } from 'util/application-type';

export const handleApplyNow = (school) => {
  const applicationTypeCode = applicationType(school);

  if (applicationTypeCode === 2) {
    trackEvent('open_apply_now_parent_portal', school.externalapplication_url);
    window.open(`${school.externalapplication_url}`, 'blank', 'scrollbars=yes, toolbar=no');
  } else if (applicationTypeCode === 1) {
    const url = `${schoolUrl.build(school).replace('?', '')}/application`;
    trackEvent('open_apply_now_parent_portal', school.externalapplication_url);
    browserHistory.push(url);
  }
};

const trackEvent = (action) => {
  trackEventOnGoogle(action);
};

const trackEventOnGoogle = (action) => {
  switch (action) {
    case 'open_apply_now_parent_portal':
      window.gtag('event', 'conversion', { send_to: config.google.gtag.tags.open_apply_now_parent_portal });
      break;
  }
};

/* validate if the external application is from jotform */
export const isJotformApplication = (school) => {
  if (school.open_enrollment && school.externalapplication_url.trim()) {
    const isJotform = school.externalapplication_url.includes('jotform');

    if (isJotform) return true;
  }
  return false;
};

export const redirectToApplication = (school) => {
  const url = `${schoolUrl.build(school).replace('?', '')}/application`;
  trackEvent('open_apply_now_parent_portal', school.externalapplication_url);
  browserHistory.push(url);
};
