import React from 'react';
import { Security16 } from '@carbon/icons-react';
import { t, tString } from 'components/translation/Translation';
import styles from './disclaimer.module.scss';

export const Disclaimer = ({ className, contact }) => {
  return (
    <div className={`${styles.disclaimer} ${className}`}>
      <Security16 />
      <p className="body-small">
        {tString('contactSchool:safetyLabel')}
        {contact && (
          <>
            {t('contactSchool:safetyLabelLink')}
            <a href="https://static.schola.com/ScholaTOSFinal.pdf" rel="noreferrer" target="_blank">
              {tString('schoolProfile:unclaimedForm.terms')}
            </a>

            {tString('schoolProfile:unclaimedForm.and')}
            <a href="https://static.schola.com/ScholaPrivacyFinal.pdf" rel="noreferrer" target="_blank">
              {tString('schoolProfile:unclaimedForm.privacy')}
            </a>
          </>
        )}
      </p>
    </div>
  );
};
