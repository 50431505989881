import { getProfile } from 'util/auth';
import { scholaApi } from '.';
import { transformExternalReviews } from 'components/pages/school-profile/utils';

const userId = getProfile()?.sub;

const schoolApi = scholaApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchoolById: builder.query({
      query: (id) => `schools/${id}`,
      keepUnusedDataFor: 60 * 5,
      transformResponse: (response) => {
        // filter school response to just get the active features:
        const activeFeatures = response.features.filter((feat) => feat._pivot_type === 'included');
        return {
          ...response,
          features: activeFeatures,
        };
      },
    }),
    getStudents: builder.query({
      query: () => `user/${userId}/students`,
    }),
    getSchoolDemographics: builder.query({
      query: (id) => `schools/${id}/school-demographics`,
      keepUnusedDataFor: 60 * 5,
    }),
    getExternalReviews: builder.query({
      query: (id) => `schools/${id}/sites-reviews`,
      keepUnusedDataFor: 60 * 5,
      transformResponse: (response) => transformExternalReviews(response),
    }),
    getUserReviews: builder.query({
      query: (id) => `schools/${id}/user-reviews/list`,
    }),
    getNearbySchools: builder.query({
      query: ({ lat, lon }) => `schools/top_nearby?lat=${lat}&lon=${lon}`,
    }),
    getTestimonials: builder.query({
      query: (id) => `schools/${id}/testimonial`,
    }),
    getFeatures: builder.query({
      query: () => `features`,
    }),
    getSchoolsV2: builder.query({
      query: ({ query, objectQuery }) => {
        if (objectQuery.lat && objectQuery.lon) {
          const completeQuery = `schools/searchv2?${query}`;
          return completeQuery;
        }

        return undefined;
      },
      transformResponse: (response, _, { objectQuery }) => {
        response = objectQuery.school_types.length
          ? response.results.filter((school) => school.type === 'public charter')
          : response.results;

        if (objectQuery.f.length) {
          const featureIndexes = objectQuery.f.map((item) => item.i);

          response = response.filter((school) => {
            const schoolFeatureIds = school.features_weightage.map((feature) => feature.feature_id);
            return featureIndexes.every((featureIndex) => schoolFeatureIds.includes(featureIndex));
          });
        }
        return {
          response,
        };
      },
    }),
    contactSchool: builder.mutation({
      query: (payload) => ({
        url: `leads`,
        method: 'post',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSchoolByIdQuery,
  useContactSchoolMutation,
  useGetStudentsQuery,
  useGetSchoolDemographicsQuery,
  useGetExternalReviewsQuery,
  useGetNearbySchoolsQuery,
  useGetTestimonialsQuery,
  useGetUserReviewsQuery,
  useGetSchoolsV2Query,
  useGetFeaturesQuery,
} = schoolApi;
