import React from 'react';
import PropTypes from 'prop-types';
import { useGetSchoolByIdQuery, useGetSchoolDemographicsQuery } from 'redux/api/school-api';
import { concat } from 'lodash';
import { t, tString } from 'components/translation/Translation';
import { useFeaturesJoin } from '../hooks/useFeaturesJoin';
import { SchoolFeatures } from '../school-features/school-features';
import { useDisplayFeatures } from '../hooks/useDisplayFeatures';
import { SchoolMap, CategoryFeature, MoreAbout } from '../components';
import { categoriesConstants } from '../constants';
import { CATEGORIES_SUBTITLES } from '../constants/categories-subtitles';
import { useStaffMembers } from '../hooks/useStaffMembers';

import styles from './info-section.module.scss';
import MessageUnclaimed from '../components/message-unclaimed/message-unclaimed';

const {
  categoriesEnum: {
    SPORTS_OUTDOORS,
    STUDENT_GOVERNMENT_LEADERSHIP,
    ENVIRONMENTAL,
    VOLUNTEER_COMMUNITY,
    CULTURAL,
    ART_COMMUNICATION,
    SCIENCES_TECHNOLOGY,
    SCHOOL_FACILITIES,
    SPECIAL_NEEDS_SUPPORT,
    SPECIAL_NEEDS,
    SPECIFIC_EDUCATIONAL_APPROACH,
    CORE_KNOWLEDGE,
    HIGH_SCHOOL_ONLY,
    SCHOOL_CLASSES,
    HIGH_SCHOOL_SPECIFIC_CLASSES,
  },
} = categoriesConstants;

export const InfoSection = ({ school, screenWidth }) => {
  const featureCategories = useFeaturesJoin(school?.features);
  const topFeatures = featureCategories.topFeatures;

  return (
    <main className={styles.main}>
      {!school.claimed && <MessageUnclaimed schoolId={school.id} />}
      {useDisplayFeatures(topFeatures) && (
        <SchoolFeatures
          topFeatures={topFeatures}
          title={
            screenWidth >= 768
              ? tString('schoolProfile:schoolFeatures.title')
              : tString('schoolProfile:schoolFeatures.titleSmall')
          }
          description={screenWidth >= 768 ? tString('schoolProfile:schoolFeatures.description') : null}
        />
      )}
      {school?.images.length > 0 && (
        <SchoolMap school={school} className={screenWidth <= 767 ? 'spacing-pb-24' : 'spacing-pb-48'} />
      )}
      <MoreAbout school={school} screenWidth={screenWidth} />

      <DisplayCategories school={school} />
    </main>
  );
};

export const DisplayCategories = ({ school }) => {
  const schoolId = school.id;

  const featureCategories = useFeaturesJoin(school?.features);

  const { data: schoolDemographics } = useGetSchoolDemographicsQuery(schoolId, {
    refetchOnMountOrArgChange: true,
  });
  const staffInfo = useStaffMembers(schoolDemographics);
  const clubsFeatures = concat(
    featureCategories[STUDENT_GOVERNMENT_LEADERSHIP] ?? [],
    featureCategories[ENVIRONMENTAL] ?? [],
    featureCategories[VOLUNTEER_COMMUNITY] ?? [],
    featureCategories[CULTURAL] ?? [],
    featureCategories[ART_COMMUNICATION] ?? [],
    featureCategories[SCIENCES_TECHNOLOGY] ?? []
  );

  const curriculumFeatures = {
    [CATEGORIES_SUBTITLES.schoolClasses]: featureCategories[SCHOOL_CLASSES] ?? [],
    [CATEGORIES_SUBTITLES.highSchoolSpecificClasses]: featureCategories[HIGH_SCHOOL_SPECIFIC_CLASSES] ?? [],
  };

  const extracurricularFeatures = {
    [CATEGORIES_SUBTITLES.sports]: featureCategories[SPORTS_OUTDOORS] ?? [],
    [CATEGORIES_SUBTITLES.clubs]: clubsFeatures ?? [],
  };

  const facilitiesFeatures = {
    [CATEGORIES_SUBTITLES.facilities]: featureCategories[SCHOOL_FACILITIES] ?? [],
    [CATEGORIES_SUBTITLES.staff]: staffInfo ?? [],
  };

  const specialNeedsFeatures = {
    [CATEGORIES_SUBTITLES.programs]: featureCategories[SPECIAL_NEEDS_SUPPORT] ?? [],
    [CATEGORIES_SUBTITLES.specialNeedsSupported]: featureCategories[SPECIAL_NEEDS] ?? [],
  };

  const academicFeatures = {
    [CATEGORIES_SUBTITLES.specificEducationalApproach]: featureCategories[SPECIFIC_EDUCATIONAL_APPROACH] ?? [],
    [CATEGORIES_SUBTITLES.coreKnowledge]: featureCategories[CORE_KNOWLEDGE] ?? [],
    [CATEGORIES_SUBTITLES.highSchoolPrograms]: featureCategories[HIGH_SCHOOL_ONLY] ?? [],
    [CATEGORIES_SUBTITLES.schoolCurriculum]: curriculumFeatures ?? {},
  };
  return (
    <>
      {useDisplayFeatures(academicFeatures) && (
        <div id="academics">
          <CategoryFeature title={tString('schoolProfile:academics.title')} features={academicFeatures} academics />
        </div>
      )}
      {useDisplayFeatures(extracurricularFeatures) && (
        <div id="extracurricular">
          <CategoryFeature
            title={t('schoolProfile:extracurricularActivities.title')}
            features={extracurricularFeatures}
          />
        </div>
      )}
      {useDisplayFeatures(facilitiesFeatures) && (
        <div id="facilities">
          <CategoryFeature title={t('schoolProfile:facilities.title')} features={facilitiesFeatures} />
        </div>
      )}
      {useDisplayFeatures(specialNeedsFeatures) && (
        <div id="specialneeds">
          <CategoryFeature title={t('schoolProfile:specialNeeds.title')} features={specialNeedsFeatures} />
        </div>
      )}
    </>
  );
};
