import React from 'react';
import styles from './extended-feature.module.scss';

/**
 *
 * @param {object} props
 * @param {any} props.icon
 * @param {string} props.title
 * @param {Array<string>} props.features
 * @returns
 */
export const ExtendedFeature = ({ icon, title, features }) => {
  return (
    <div className={styles.extendedFeatureContainer}>
      <div className={styles.icon}>
        {icon} <h4 className="body-large">{title}</h4>
      </div>
      <div className={styles.text}>
        {features.length > 0 ? (
          <ul>
            {features.map((feature, index) => {
              return <li key={index}>{feature}</li>;
            })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
