import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetExternalReviewsQuery, useGetUserReviewsQuery } from 'redux/api/school-api';
import { logosReviews } from '../../utils';
import StarsExperience from 'components/pages/school-profile-page/components/TestimonialAndReview/StarsExperience';
import ListReviews from 'components/pages/school-profile-page/components/TestimonialAndReview/ListReviews';
import FormReview from 'components/pages/school-profile-page/components/TestimonialAndReview/FormReview';
import { getProfile } from 'util/auth';
import { browserHistory } from 'react-router';
import localstorage from 'store2';
import { useQueryState } from 'components/pages/parent-portal/track/hooks/useQueryState';
import styles from './reviews.module.scss';
import { t } from 'components/translation/Translation';

export const Reviews = ({ school }) => {
  const loggedIn = useSelector((state) => !!state.auth.token);

  const [flag, setFlag] = useState(false);

  const { data: reviews, isSuccess, refetch } = useGetUserReviewsQuery(school.id);
  const { data: externalReviews, isSuccess: reviewsSuccess } = useGetExternalReviewsQuery(school.id);

  const [rateGeneral, setRateGeneral] = useState({
    general: 0,
  });

  const [isModalReview, setIsModalReview] = useState(false);

  const loginRedirect = () => {
    const currentPath = window.location.pathname;

    localstorage.set('redirectLocation', `${currentPath}?source=reviews`);
    browserHistory.push({ pathname: '/login' });
  };

  const handleModalReview = () => {
    loggedIn ? setIsModalReview(true) : loginRedirect();
  };

  useEffect(() => {
    refetch();
  }, [flag]);

  const userHasReview = useMemo(
    () => (loggedIn ? reviews?.results?.map(({ user_id: userId }) => userId).includes(getProfile().sub) : false),
    [reviews]
  );

  const { removeQueryParam } = useQueryState();

  useEffect(() => {
    const { query } = browserHistory.getCurrentLocation();
    if (query.source === 'reviews') {
      setIsModalReview(true);
    }
  }, []);

  const handleClose = () => {
    const { query } = browserHistory.getCurrentLocation();

    setIsModalReview(false);

    if (query.source === 'reviews') {
      removeQueryParam('source');
    }
  };

  return (
    <div className={styles['reviews-section']} id="reviews">
      <h3 className={styles.title}>
        {school.claimed ? t('schoolProfile:reviews:title') : t('schoolProfile:reviews.unclaimedTitle')}
      </h3>
      <div className={`${styles.reviewsHeader} spacing-mb-24`}>
        {reviewsSuccess && !externalReviews.length ? null : <ExternalReviews externalReviews={externalReviews} />}
        {isSuccess && !reviews.results.length ? null : <PercentageReviews reviews={reviews?.results} />}
      </div>

      {!userHasReview && (
        <OveralRatingBanner
          schoolName={school.name}
          handleModalReview={handleModalReview}
          rateGeneral={rateGeneral}
          setRateGeneral={setRateGeneral}
          claimed={school.claimed}
        />
      )}

      {isModalReview && (
        <FormReview
          school_id={school.id}
          onClose={handleClose}
          schoolName={school.name}
          loggedIn={loggedIn}
          setFlag={setFlag}
          rateGeneral={rateGeneral}
        />
      )}

      {isSuccess && <ListReviews reviews={reviews} />}
    </div>
  );
};

const PercentageReviews = ({ reviews }) => {
  const arrStars = useMemo(() => {
    const count = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
    reviews?.forEach((item) => {
      if (item.rate) {
        count[item.rate]++;
      }
    });

    const arrStars = [];

    for (let rate = 5; rate >= 1; rate--) {
      const value = (count[rate] * 100) / reviews?.length;
      arrStars.push({ label: rate.toString(), value: Number(value).toFixed() });
    }

    return arrStars;
  }, [reviews]);

  return (
    <div>
      {arrStars.map((item, index) => (
        <div key={index} className={styles.barStar}>
          <div className={styles.barStar_num}>
            <p>{item.label}</p>
            <StarsExperience color="#DADEE5" lenght={1} spaceBetween={0} size={18} notPointer onlyView />
          </div>

          <div className={styles.barStar_progress}>
            <Progress progress={item.value} />
            <p className="body-small">{item.value}%</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Progress = ({ progress }) => {
  return (
    <div className={styles.background}>
      <div className={styles.barColor} style={{ width: progress }} />
    </div>
  );
};

const ExternalReviews = ({ externalReviews }) => {
  return (
    <div className={styles.externalWrapper}>
      {externalReviews?.map((item) => (
        <div key={item.id} className={styles.externalReview}>
          <img width="20" height="20" src={logosReviews[item.site]} alt="Site Logo" />

          <StarsExperience color="#f0ad1b" value={item.ranking} spaceBetween={5} size={14} notPointer onlyView />

          <span className="body-small">{`${item.total} Reviews`}</span>
        </div>
      ))}
    </div>
  );
};

const OveralRatingBanner = ({ schoolName, handleModalReview, rateGeneral, setRateGeneral }) => {
  return (
    <div className={`${styles.leaveReview} spacing-p-24 spacing-pb-48`}>
      <img
        src="https://static.schola.com/profile/empty-reviews-figure.webp"
        alt="Share your experience"
        width="120"
        height="121"
      />
      <h4 className="spacing-mt-24 spacing-mb-48">{t('schoolProfile:reviews.reviewOverall', { schoolName })}</h4>
      <div onClick={handleModalReview}>
        <StarsExperience
          color="#008CFF"
          spaceBetween={15}
          size={42}
          name="general"
          value={rateGeneral.general}
          state={rateGeneral}
          setState={setRateGeneral}
          section="reviews"
        />
      </div>
    </div>
  );
};
