import React from 'react';
import style from './single-feature.module.scss';

/**
 * @param {object} props
 * @param {any} props.icon
 * @param {string} props.title
 * @param {string} props.text
 */
const SingleFeature = ({ icon, title, text }) => {
  return (
    <div className={style.container}>
      {icon}
      <div className={`${style.textContainer} spacing-ml-12`}>
        <p className={style.title}>{title}</p>
        <p className={style.text}>{text}</p>
      </div>
    </div>
  );
};

export default SingleFeature;
