import React from 'react';
import { t, tString } from 'components/translation/Translation';
import PropTypes from 'prop-types';

import { UnclaimedBanner } from './unclaimed-banner';
import { ListActions } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { openContact } from 'redux/slices/contact-school.slice';
import styles from './school-actions.module.scss';

export const SchoolActions = ({ school }) => {
  const { claimed } = school;
  const { availableTours } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const handleOpen = () => dispatch(openContact());

  return (
    availableTours && (
      <div className={styles.schoolActions}>
        {/* {!claimed && (
          <div className={styles.unclaimedContainer} id="ContactUnClaimed">
            <h6 className={styles.title}>{t('schoolProfile:actions.connect')}</h6>
            <p className={styles.unclaimedText}>{tString('schoolProfile:actions.unclaimedText')}</p>
            <ListActions school={school} />
            <UnclaimedBanner handleOpen={handleOpen} />
          </div>
        )} */}
        <div className={styles.container} id="Contact">
          <ListActions school={school} />
        </div>
      </div>
    )
  );
};

SchoolActions.propTypes = {
  school: PropTypes.object.isRequired,
};
