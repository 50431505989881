import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './gallery-grid-mobile.module.scss';
import pixels from 'util/pixels';

const GalleryGridMobile = ({ imagesMobile, name }) => {
  const MobileGalleryClasses = {
    [styles.main]: imagesMobile?.length === 1,
    [styles.multi]: imagesMobile?.length > 1,
  };

  return (
    <div className={classNames(styles.mobileGallery, MobileGalleryClasses)}>
      {imagesMobile?.map((image, idx) => {
        const filenameArr = image?.filename.split('/');
        const largeName = `/s-${filenameArr.pop()}`;

        const smallUrl = filenameArr.join('/') + largeName;

        return (
          <div key={idx} className={styles.mobileGallery_item}>
            <img
              src={smallUrl}
              alt={`${name} photo ${idx}`}
              onError={(error) => {
                error.target.src = pixels.lightblue;
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

GalleryGridMobile.propTypes = {
  name: PropTypes.string,
  imagesMobile: PropTypes.array,
};

export default GalleryGridMobile;
