import React from 'react';
import PropTypes from 'prop-types';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import styles from './school-name.module.scss';

export const SchoolName = ({ name, claimed, logo }) => {
  return (
    <>
      {logo && (
        <div className={styles.logo}>
          <img src={logo} alt={`${name} logo`} />
        </div>
      )}
      <div className={styles.name}>
        <h1 className={styles.schoolName}>
          {name}
          {claimed && <CheckmarkFilled16 fill="#006B8F" className={styles.claimed} />}
        </h1>
      </div>
    </>
  );
};

SchoolName.propTypes = {
  name: PropTypes.string.isRequired,
  claimed: PropTypes.bool,
  logo: PropTypes.string,
};
