import { useState, useEffect } from 'react';

export const useFeaturesJoin = (features) => {
  const [schoolFeatures, setSchoolFeatures] = useState({});

  useEffect(() => {
    if (features && features.length > 0) {
      const result = features.reduce((acc, feature) => {
        const groupName = feature.group.name;
        const isTopFeature = feature._pivot_is_top;

        if (!acc[groupName]) {
          acc[groupName] = [];
        }

        if (feature._pivot_type === 'included') {
          acc[groupName].push(feature);
        }

        if (isTopFeature && feature._pivot_type === 'included') {
          if (!acc.topFeatures) {
            acc.topFeatures = [];
          }
          acc.topFeatures.push(feature);
        }
        return acc;
      }, {});

      setSchoolFeatures(result);
    }
  }, [features]);

  return schoolFeatures;
};
