import React, { useMemo } from 'react';
import SingleFeature from '../../single-feature/single-feature';
import { Building24, EventsAlt24, Person24 } from '@carbon/icons-react';
import styles from './school-type.module.scss';
import { t } from 'components/translation/Translation';

export const SchoolType = ({ type, grades, features, className, claimed }) => {
  const displayGrades = useMemo(() => (grades ? getGradesRange(grades) : null), []);

  const groupLearningName = 'School Learning Environment';
  const learningEnviroments = features
    .filter((feat) => feat.group.name === groupLearningName)
    .map((item) => item.name.trim())
    .join(', ');

  return (
    <div className={`${styles.items} ${className}`}>
      <SingleFeature
        title={
          claimed
            ? t('schoolProfile:characteristics.claimed.schoolType')
            : t('schoolProfile:characteristics.unclaimed.schoolType')
        }
        text={type}
        icon={<Building24 fill="#5F6A7D" />}
      />
      {grades && (
        <SingleFeature
          title={
            claimed
              ? t('schoolProfile:characteristics.claimed.grades')
              : t('schoolProfile:characteristics.unclaimed.grades')
          }
          text={displayGrades}
          icon={<EventsAlt24 fill="#5F6A7D" />}
        />
      )}
      {learningEnviroments && (
        <SingleFeature
          title={
            claimed
              ? t('schoolProfile:characteristics.claimed.learningEnvironment')
              : t('schoolProfile:characteristics.unclaimed.learningEnvironment')
          }
          text={learningEnviroments}
          icon={<Person24 fill="#5F6A7D" />}
        />
      )}
    </div>
  );
};

const getGradesRange = (grades) => {
  const intGrade = (grade) => {
    let ordinal = 0;
    switch (grade) {
      case 'Pre-K':
        ordinal = -3;
        break;
      case 'TK':
        ordinal = -2;
        break;
      case 'HS':
        ordinal = -1;
        break;
      case 'K':
        ordinal = 0;
        break;
      default:
        ordinal = parseInt(grade, 10);
        break;
    }
    return ordinal;
  };
  let rangeBegin = '';
  let rangeEnd = '';
  let ranges = [];
  let lastIndex;
  grades.split(',').map((grade) => {
    if (rangeBegin === '') {
      rangeBegin = grade === 'HS' ? 'Head Start' : grade;
    } else if (lastIndex + 1 !== intGrade(grade)) {
      const _range = rangeBegin !== rangeEnd ? `${rangeBegin}-${rangeEnd}` : rangeBegin;
      ranges.push(_range);
      rangeBegin = grade === 'HS' ? 'Head Start' : grade;
      rangeEnd = grade === 'HS' ? 'Head Start' : grade;
    }
    rangeEnd = grade === 'HS' ? 'Head Start' : grade;
    lastIndex = intGrade(grade);
    return null;
  });
  const _range = rangeBegin !== rangeEnd ? `${rangeBegin}-${rangeEnd}` : rangeBegin;
  ranges.push(_range);
  return ranges.join(', ');
};
