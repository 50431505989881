import React, { useMemo } from 'react';
import { Buttonv2 as Button } from 'schola-components';
import styles from './hero-buttons.module.scss';
import { ViewFilled24 } from '@carbon/icons-react';
import { tString } from 'components/translation/Translation';

export const HeroButtons = ({ className, applyAction, contactAction, isEnrolling }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.buttonFlex}>
        {isEnrolling && (
          <Button fullWidthMobile color="primary" onClick={applyAction}>
            {tString('schoolProfile:CTA.applyNow')}
          </Button>
        )}

        <Button
          fullWidthMobile
          color={!isEnrolling ? 'primary' : 'secondary'}
          id="btnContactModalOpen"
          onClick={contactAction}>
          {tString('contactSchool:contactSchool')}
        </Button>
        <CurrentViews />
      </div>
    </div>
  );
};

const CurrentViews = () => {
  const randomViews = useMemo(() => Math.floor(Math.random() * (21 - 14 + 1)) + 14, []);
  return (
    <div className={styles.badgeRadomGray}>
      <div>
        <ViewFilled24 className={styles.iconView} />
      </div>
      <span className={styles.numRandom}>{randomViews}</span> Parents Viewing
    </div>
  );
};
